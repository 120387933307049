import { AxiosInstance } from "axios";
import type { MetaDTO } from "packages/models/sitemeta/sitemeta.dto";
import type { Meta } from "packages/models/sitemeta/sitemeta.schema";

export default (api: AxiosInstance) => ({
  route: "/meta",
  get() {
    return api.get<Meta>(this.route);
  },
  put(data: MetaDTO) {
    return api.put<Meta>(this.route, data);
  },
});
