import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MenuState {
  open: boolean;
}

const initialState: MenuState = {
  open: false,
};

export const menuSlice = createSlice({
  name: "menu-state",
  initialState,
  reducers: {
    set(state, action: PayloadAction<Partial<MenuState>>) {
      state.open = action.payload.open ?? state.open;
    },
  },
});
