import React, { Fragment, useMemo, useState } from "react";
import PrimaryLargeButton from "packages/web-ui/Buttons/PrimaryLargeButton";
import {
  useGetUser,
  useUpdateUser,
} from "packages/api-services/queries/user.query";
import { useForm } from "react-hook-form";
import { UpdateUserDTO } from "packages/models/user/user.dto";
import UncontrolledDefaultTextInput from "packages/web-ui/Inputs/UncontrolledDefaultTextInput";
import { ProfessionType } from "packages/models/user/user.schema";
import UncontrolledSelectInput from "packages/web-ui/Inputs/UncontrolledSelectInput";
import { toast } from "react-toastify";
import userState from "packages/state-management/state/user.state";

interface Props {
  onDismiss: () => void;
}
const phone = encodeURIComponent("+919113602426");
export default function CareerGuidance({ onDismiss }: Props) {
  const [stage, setStage] = useState<"info" | "career">("info");
  const { user } = userState();
  const text = encodeURIComponent(
    [
      `Hello, my name is ${user.name}, and I am interested in Career Guidance.`,
      user.phone && `Phone Number: ${user.country_code}${user.phone}`,
      user.email && `Email Address: ${user.email}`,
      `My username on the platform is: ${user.username}`,
    ]
      .filter(Boolean)
      .join("\n")
  );

  if (stage === "career") {
    return (
      <div className="m-4">
        <h1 className="heading-h1 mb-4">Career Guidance</h1>
        <p className="overline-01">About:</p>
        <p className="body-b2 mt-2">
          Schedule an online 1:1 meeting with an industry expert who will guide
          you with career interests & goals
        </p>
        <div className="flex gap-4 mt-8">
          <PrimaryLargeButton
            type="contained"
            label="I'm Interested"
            onClick={() => {
              onDismiss();
              window.open(`whatsapp://send?phone=${phone}&text=${text}`);
            }}
          />
          <PrimaryLargeButton
            type="text"
            label="Maybe later..."
            onClick={onDismiss}
          />
        </div>
      </div>
    );
  }

  return (
    <ProfileInfo
      onDismiss={onDismiss}
      onNext={() => {
        setStage("career");
      }}
    />
  );
}

const ProfileInfo: React.FC<{ onDismiss: () => void; onNext: () => void }> = ({
  onDismiss,
  onNext,
}) => {
  const user_query = useGetUser().query;
  const update_user = useUpdateUser({
    onError: () => {
      toast.error("Unable to add fields");
    },
    onSuccess: (data) => {
      const user = data?.data;
      if (user.goal === "Career Guidance") onNext();
      else onDismiss();
    },
  }).mutation;
  const user_data = user_query.data?.data;
  const { register, getValues, watch } = useForm<UpdateUserDTO>({
    defaultValues: {
      occupation_type: user_data?.occupation_type,
      goal: user_data?.goal,
    },
  });

  const occupation = watch("occupation_type") ?? user_data?.occupation_type;

  return (
    <div className="m-4">
      <h1 className="heading-h1 mb-4">Let's Get Going</h1>
      <div className="mt-4 flex flex-col gap-y-2">
        <UncontrolledDefaultTextInput
          register={register("address.city")}
          label="City/Town:"
        />
        {occupation === "Student" ? (
          <Fragment>
            <UncontrolledDefaultTextInput
              register={register("educational_institution")}
              label="Educational Institution:"
            />
            <UncontrolledDefaultTextInput
              label="Year of Passout:"
              register={register("passout_year", { valueAsNumber: true })}
            />
          </Fragment>
        ) : (
          <Fragment>
            <UncontrolledDefaultTextInput
              register={register("current_employer")}
              label="Current Employer"
            />
            <UncontrolledDefaultTextInput
              register={register("experience", { valueAsNumber: true })}
              label="Years of Experience:"
            />
          </Fragment>
        )}
        <PrimaryLargeButton
          type="contained"
          label="Submit"
          loading={update_user.isLoading || user_query.isLoading}
          onClick={() => {
            if (!user_data) return;
            const data = getValues();
            update_user.mutate({
              name: user_data?.name,
              email: user_data?.email,
              phone: user_data?.phone,
              current_employer: data.current_employer,
              experience: +(data.experience ?? "0"),
              passout_year: +(data.passout_year ?? "0"),
              educational_institution: data.educational_institution,
            });
          }}
        />
      </div>
    </div>
  );
};
