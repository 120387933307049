import * as Snippet from "@segment/snippet";

export type App = "learner" | "instructor" | "admin";

export const keys: Record<App, string> = {
  learner: "dH1Fjmxp8QPwD6JI800jPBt4D56vPkYV",
  admin: "",
  instructor: "",
};

export default function initiateAnalytics(app: App) {
  const apiKey = keys[app];
  const options: Snippet.Options = {
    apiKey,
  };

  if (process.env.NODE_ENV === "development") return Snippet.max(options);
  return Snippet.min(options);
}

export namespace Segment {
  const safeAnalytics = (callback: (analytics: any) => void) => {
    if (process.env.NODE_ENV === "development") return;
    if (!window || typeof window === "undefined") return;
    if (!("analytics" in window)) return;

    const analytics = window.analytics;

    callback(analytics);
  };
  export const page = () => {
    safeAnalytics((analytics) => analytics.page());
  };

  export const identity = (
    user_id: string,
    user: {
      name: string;
      phone: string;
      email: string;
      username: string;
    },
  ) => {
    safeAnalytics((analytics) => analytics.identify(user_id, user));
  };

  export const track = (
    eventName: string,
    properties: Record<string, string | number | boolean>,
  ) => {
    safeAnalytics((analytics) => analytics.track(eventName, properties));
  };
}
