import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "packages/models/auth/dto/role.dto";

export interface UserState {
  loggedIn: boolean;
  token: string;
  username: string;
  client_ready: boolean;
  email: string;
  phone: string;
  country_code: string;
  pop_up: "career" | "info" | "none" | "payment";
  name: string;
  role: Role;
}

const initialState: UserState = {
  client_ready: false,
  loggedIn: false,
  name: "",
  token: "unprotected",
  username: "",
  email: "",
  phone: "",
  country_code: "",
  pop_up: "none",
  role: "Learner",
};

export const userSlice = createSlice({
  name: `user-state`,
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<UserState>>) => {
      state.client_ready = action.payload.client_ready ?? state.client_ready;
      state.loggedIn = action.payload.loggedIn ?? state.loggedIn;
      state.token = action.payload.token ?? state.token;
      state.username = action.payload.username ?? state.username;
      state.email = action.payload.email ?? state.email;
      state.phone = action.payload.phone ?? state.phone;
      state.country_code = action.payload.country_code ?? state.country_code;
      state.pop_up = action.payload.pop_up ?? state.pop_up;
      state.name = action.payload.name ?? state.name;
      state.role = action.payload.role ?? state.role;
    },
  },
});
