import { useRouter } from "next/navigation";
import {
  useGetUser,
  useUpdateUser,
} from "packages/api-services/queries/user.query";
import { Segment } from "packages/frontend-services/analytics";
import { UpdateUserDTO } from "packages/models/user/user.dto";
import { ProfessionType } from "packages/models/user/user.schema";
import userState from "packages/state-management/state/user.state";
import PrimaryLargeButton from "packages/web-ui/Buttons/PrimaryLargeButton";
import SelectInput from "packages/web-ui/Inputs/SelectInput";
import UncontrolledDefaultTextInput from "packages/web-ui/Inputs/UncontrolledDefaultTextInput";
import UncontrolledSelectInput from "packages/web-ui/Inputs/UncontrolledSelectInput";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  onDismiss: () => void;
}

interface IDetails {
  name: string;
  occupation_type: UpdateUserDTO["occupation_type"];
  goal_type: "Career Guidance" | "Others" | "Upskilling" | "Jobs";
  goals: UpdateUserDTO["goal"];
}
export default function ({ onDismiss }: Props) {
  const { register, setValue, control, getValues } = useForm<IDetails>({
    mode: "onBlur",
  });
  const goalTypeController =
    useState<"Career Guidance" | "Others" | "Upskilling" | "Jobs">(
      "Career Guidance"
    );
  const { setUser } = userState();

  const userquery = useGetUser({ enabled: false }).query;

  const updateUserMutation = useUpdateUser({
    onSuccess: () => {
      setUser({
        loggedIn: true,
        pop_up: "career",
      });
    },
  });

  const handleClick = () => {
    const user = userquery?.data?.data;
    if (!user) return;
    const values = getValues();
    const occupation_type = values.occupation_type;
    const goals =
      goalTypeController[0] === "Others" ? values.goals : goalTypeController[0];

    // track user identity with segment
    Segment.identity(user.username, {
      username: user.username,
      name: user.name,
      phone: user.phone,
      email: user.email,
    });

    updateUserMutation.mutation.mutate({
      phone: user.phone,
      email: user.email,
      occupation_type,
      name: user.name,
      goal: goals,
    });
  };

  return (
    <div className="m-4">
      <h1 className="heading-h1 mb-4">Welcome:</h1>
      <p className="body-b2">Let's get going..</p>

      <UncontrolledSelectInput
        className="my-4"
        register={register("occupation_type")}
        label="You are a"
        options={["Student", "Professional"] as ProfessionType[]}
      />
      <div className="flex gap-4 w-full flex-col sm:flex-row">
        <SelectInput
          controller={goalTypeController}
          label="You are here for:"
          options={
            [
              "Career Guidance",
              "Upskilling",
              /* "Jobs", */
              "Others",
            ] as IDetails["goal_type"][]
          }
        />
        {goalTypeController[0] === "Others" && (
          <UncontrolledDefaultTextInput
            className="w-full"
            register={register("goals")}
            label="Mention here"
          />
        )}
      </div>

      <PrimaryLargeButton
        label="Continue"
        type="contained"
        className="w-full mt-12"
        onClick={handleClick}
      />
    </div>
  );
}
