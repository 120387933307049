"use client";

import StateProvider from "packages/state-management/Provider";
import ApiProvider from "packages/api-services/interceptor/ApiProvider";
import { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import {
  useGetUser,
  useUpdateUser,
} from "packages/api-services/queries/user.query";
import userState from "packages/state-management/state/user.state";
import { getCookie, hasCookie, setCookie } from "cookies-next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UserState } from "packages/state-management/store/user.store";
import { ToastContainer } from "react-toastify";
import { useGetMeta } from "packages/api-services/queries/meta.query";
import LoadingScreen from "packages/web-ui/Cosmetic/LoadingScreen";
import "react-toastify/dist/ReactToastify.css";
import { redirect, useRouter } from "next/navigation";
import { Segment } from "packages/frontend-services/analytics";

const CLIENT_ID =
  "430836859141-iaed66mngrm9eua3ac7dl8p93627m7u4.apps.googleusercontent.com";

function saveUserData(data: UserState) {
  localStorage.setItem("user", JSON.stringify(data));
  setCookie("token", data.token);
}

export default function ContextProvider({ children }: { children: ReactNode }) {
  return (
    <Fragment>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <StateProvider
          listener={(data) => {
            const userData = data["user-state"];
            saveUserData(userData);
          }}
        >
          <ApiProvider>
            <ToastContainer autoClose={3000} />
            <MainApp>{children}</MainApp>
          </ApiProvider>
        </StateProvider>
      </GoogleOAuthProvider>
    </Fragment>
  );
}

const MainApp = ({ children }: React.PropsWithChildren) => {
  const { user, setUser } = userState();
  const userFetched = useRef(false);
  const router = useRouter();

  // add queries that are needed at startup
  const metaQuery = useGetMeta({
    enabled: false,
  });

  const updateUser = useUpdateUser().mutation;

  const getUserQuery = useGetUser({
    enabled: false,
    retry: 1,
    onError: (error) => {
      const status = error.response?.status;
      if (status == 401) {
        setUser({
          loggedIn: false,
          token: "",
        });
      }
    },
    onSuccess: (data) => {
      userFetched.current = true;
      const userData = data.data;

      /* alert(JSON.stringify(userData, null, 2)); */

      if (userData.is_blocked) {
        alert("Your account has been blocked by the admins.");
        setUser({
          loggedIn: false,
          token: "",
        });
        localStorage.clear();
        document.cookie = "";
        router.push("/");
        return;
      }

      if (userData.is_deactivated) {
        if (
          confirm(
            "Your account is deactivated. Are you sure you want to activate it.",
          )
        ) {
          updateUser.mutate({
            is_deactivated: false,
            email: userData.email,
            phone: userData.phone,
            name: userData.name,
          });
        } else {
          localStorage.clear();
          document.cookie = "";
          setUser({
            loggedIn: false,
            token: "",
          });
          router.push("/");
          return;
        }
        // do something
      }

      Segment.identity(user.username, {
        email: userData.email,
        phone: userData.phone,
        name: userData.name,
        username: userData.username,
      });

      setUser({
        email: userData.email,
        phone: userData.phone,
        username: userData.username,
        country_code: userData.country_code,
        name: userData.name,
      });
      metaQuery.query.refetch();
    },
  });

  useEffect(() => {
    if (!hasCookie("token")) {
      const localData = localStorage.getItem("user");
      if (!localData) {
        setUser({
          loggedIn: false,
          client_ready: true,
        });
      } else {
        console.log(localData);
        const data = JSON.parse(localData);
        setUser(data);
      }
    } else {
      const token = getCookie("token")?.toString();
      setUser({
        token,
        loggedIn: true,
        client_ready: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!user.loggedIn) return;
    if (!userFetched.current) getUserQuery.query.refetch();
  }, [user.loggedIn, user.client_ready, getUserQuery.query]);

  if (!user.client_ready) return <LoadingScreen />;

  return <Fragment>{children}</Fragment>;
};
