"use client";
import {
  QueryClient,
  QueryClientProvider,
  QueryKey,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useQueryClient as useQueryClientCore,
} from "@tanstack/react-query";
import React, { ReactNode } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
const client = new QueryClient();

export default function ApiProvider(props: {
  children: ReactNode;
  customClient?: QueryClient;
}) {
  return (
    <QueryClientProvider client={props.customClient ?? client}>
      {props.children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export const useQueryClient = useQueryClientCore;

export type AppMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = Omit<
  UseMutationOptions<
    AxiosResponse<TData>,
    AxiosError<ErrorResponse<TError>>,
    TVariables,
    TContext
  >,
  "mutationKey" | "mutationFn"
>;

export type AppQueryOption<
  TQueryFnData = unknown,
  TError = unknown,
  TData = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseQueryOptions<
    AxiosResponse<TQueryFnData, any>,
    AxiosError<ErrorResponse<TError>>,
    AxiosResponse<TData, any>,
    TQueryKey
  >,
  "queryKey" | "queryFn"
>;

export type AppInfiniteQueryOption<
  TQueryFnData = unknown,
  TError = unknown,
  TData = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseInfiniteQueryOptions<
    AxiosResponse<TQueryFnData, any>,
    AxiosError<ErrorResponse<TError>>,
    AxiosResponse<TData>,
    AxiosResponse<TData>,
    TQueryKey
  >,
  "queryKey" | "queryFn"
>;

export interface ErrorResponse<T = any> {
  error: T;
  message: string;
  statusCode: number;
}
