import { User } from "@encovate-mono/models/user/user.schema";
import {
  CreatePhoneUserDTO,
  UpdateUserDTO,
  UserLoginResponse,
  UserDTO,
} from "@encovate-mono/models/user/user.dto";
import { useMutation, useQuery } from "@tanstack/react-query";
import useApiRoute from "../interceptor/api-interceptor";
import { AppMutationOptions, AppQueryOption } from "../interceptor/ApiProvider";
import usersRoute from "../routes/users.route";

export function useGetUser(options?: AppQueryOption<User, any, User, any>) {
  const api = useApiRoute(usersRoute);
  const key = [api.route, "get"];

  const query = useQuery(key, () => api.get(), options);

  return { key, query };
}

export function useGetUserByUsername(
  username: string,
  options?: AppQueryOption<User, any, User, any>
) {
  const api = useApiRoute(usersRoute);
  const key = [api.route, "get", username];

  const query = useQuery(key, () => api.getByUsername(username), options);

  return { key, query };
}

export function useCreateUser(
  options?: AppMutationOptions<UserLoginResponse, any, CreatePhoneUserDTO>
) {
  const api = useApiRoute(usersRoute);
  const key = [api.route, "post"];

  const mutation = useMutation(key, (data) => api.post(data), options);

  return { key, mutation };
}

export function useUpdateUser(
  options?: AppMutationOptions<User, any, UpdateUserDTO>
) {
  const api = useApiRoute(usersRoute);
  const key = [api.route, "put"];

  const mutation = useMutation(key, (data) => api.put(data), options);

  return { key, mutation };
}

export function useCreateGoogleUser(
  options?: AppMutationOptions<UserLoginResponse, any, string>
) {
  const api = useApiRoute(usersRoute);
  const key = [api.route, "post"];
  const mutation = useMutation(
    key,
    (idToken) => api.postGoogle(idToken),
    options
  );
  return { key, mutation };
}
