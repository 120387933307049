"use client";

import { UseFormRegisterReturn } from "react-hook-form";
import { twMerge } from "tailwind-merge";

interface Props {
  inputStyle?: string;
  register: UseFormRegisterReturn<string>;
  label?: string;
  hint?: string;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  value?: string;
  className?: string;
  secureTextEntry?: boolean;
}

export default function UncontrolledDefaultTextInput({
  className,
  inputStyle,
  register,
  hint,
  placeholder,
  error,
  label,
  disabled,
  secureTextEntry,
  value,
}: Props) {
  return (
    <label htmlFor={label} className={twMerge("flex flex-col", className)}>
      {!!label && (
        <span
          className={`body-b3 mb-2 ${disabled ? "text-tertiary-dark" : ""}`}
        >
          {label}
        </span>
      )}
      <input
        type={secureTextEntry ? "password" : "text"}
        defaultValue={value}
        className={twMerge(
          "border border-tertiary-dark heading-h5 p-3.5 placeholder:text-tertiary-dark rounded-lg heading-5 focus:border-primary-default disabled:bg-white",
          error ? "border-primary-error" : "",
          inputStyle,
        )}
        placeholder={placeholder}
        id={label}
        disabled={disabled}
        {...register}
      />
      {!!hint && (
        <span
          className={`mt-1 caption-c1 text-text-secondary ${
            error ? "text-primary-error" : ""
          }`}
        >
          {hint}
        </span>
      )}
    </label>
  );
}
