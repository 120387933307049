"use client";
import { twMerge } from "tailwind-merge";

interface Props<T extends string> {
  className?: string;
  inputStyle?: string;
  controller: [T, React.Dispatch<React.SetStateAction<T>>];
  label?: string;
  hint?: string;
  disabled?: boolean;
  error?: boolean;
  options: T[];
}

export default function SelectInput<T extends string>({
  className,
  options,
  inputStyle,
  controller,
  hint,
  error,
  label,
  disabled,
}: Props<T>) {
  const [state, setState] = controller;
  return (
    <label htmlFor={label} className={twMerge("flex flex-col", className)}>
      {!!label && (
        <span
          className={`body-b3 mb-2 ${disabled ? "text-tertiary-dark" : ""}`}
        >
          {label}
        </span>
      )}
      <select
        className={twMerge(
          "bg-white border border-tertiary-dark heading-h5 p-3.5 placeholder:text-tertiary-dark rounded-lg heading-5 focus:border-primary-default disabled:bg-white",
          error ? "border-primary-error" : "",
          inputStyle
        )}
        disabled={disabled}
        value={state}
        onChange={(e) => setState(e.target.value as T)}
      >
        {options.map((opt) => (
          <option key={`${label}-${opt}`}>{opt}</option>
        ))}
      </select>
      {!!hint && (
        <span
          className={`mt-1 caption-c1 text-text-secondary ${
            error ? "text-primary-error" : ""
          }`}
        >
          {hint}
        </span>
      )}
    </label>
  );
}
