import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FilterState {
  for: string | null;
}

const initialState: FilterState = {
  for: null,
};

export const filterSlice = createSlice({
  name: "filter-state",
  initialState,
  reducers: {
    set(state, action: PayloadAction<Partial<FilterState>>) {
      state.for ??= action.payload.for ?? null;
    },
  },
});
