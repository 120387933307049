"use client";
import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  dismissable?: boolean;
  onDismiss: () => void;
  barrierColor?: string;
  animateChild?: boolean;
  containerClassName?: string;
  speed?: number;
}
export default function Portal({
  children,
  onDismiss,
  barrierColor = "#00000020",
  dismissable = false,
  animateChild = false,
  containerClassName,
  speed = 200,
}: Props) {
  const underlay = useRef<HTMLDivElement | null>(null);
  const [scale, setScale] = useState(animateChild ? 0 : 1);

  useEffect(() => {
    if (typeof document === "undefined" || !document) return;
    const body = document.body;
    body.style.setProperty("overflow", "clip");

    return () => {
      body.style.setProperty("overflow", "unset");
    };
  }, []);

  const previousRef = useRef<number | null>(null);
  const requestRef = useRef<number | null>(null);
  const animate = (time: number) => {
    if (previousRef.current) {
      const delta = (time - previousRef.current) / speed;
      setScale((prev) => {
        if (prev === 1) {
          if (!requestRef.current) return prev;
          cancelAnimationFrame(requestRef.current);
          return prev;
        }

        return Math.min(1, prev + delta);
      });
    }

    previousRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (!animateChild) return;
    requestRef.current = requestAnimationFrame(animate);

    return () => {
      if (!requestRef.current) return;
      cancelAnimationFrame(requestRef.current);
    };
  }, [animateChild]);

  return (
    <div
      id="overlay"
      onClick={() => {
        if (!dismissable) return;
        onDismiss();
      }}
      className="fixed inset-0 grid place-items-center z-50 isolate"
      style={{ backgroundColor: barrierColor }}
    >
      <div
        ref={underlay}
        className={twMerge(
          "bg-white max-w-2xl w-full rounded-xl transition-[scale] duration-[2000ms]",
          containerClassName
        )}
        style={{
          transform: `scale(${scale})`,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
}
