import { useMutation, useQuery } from "@tanstack/react-query";
import { MetaDTO } from "packages/models/sitemeta/sitemeta.dto";
import { Meta } from "packages/models/sitemeta/sitemeta.schema";
import useApiRoute from "../interceptor/api-interceptor";
import { AppQueryOption, AppMutationOptions } from "../interceptor/ApiProvider";
import metaRoute from "../routes/meta.route";

export function useGetMeta(
  options?: AppQueryOption<Meta, any, Meta, string[]>
) {
  const api = useApiRoute(metaRoute);
  const key = [api.route, "get"];
  const query = useQuery(key, () => api.get(), options);

  return { key, query };
}

export function useUpdateMeta(
  options?: AppMutationOptions<Meta, any, MetaDTO, string[]>
) {
  const api = useApiRoute(metaRoute);
  const key = [api.route, "put"];
  const mutation = useMutation(key, (data) => api.put(data), options);

  return { key, mutation };
}
