import React, { Fragment } from "react";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import LoadingSpinner from "../Cosmetic/LoadingSpinner";

type ButtonType = "contained" | "outlined" | "soft" | "text";
interface Props {
  className?: string;
  type: ButtonType;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  loading?: boolean;
}
const PrimaryLargeButton = ({
  type,
  label,
  onClick,
  className = "",
  disabled = false,
  loading = false,
  ref,
}: Props) => {
  const buttonStyle = useMemo(() => {
    const defaultClassName =
      styleMap[type] + " px-4 py-3 rounded actions-button-large " + className;
    return twMerge(defaultClassName);
  }, [className, type, styleMap]);
  return (
    <button
      onClick={(e) => {
        if (loading) return;
        onClick?.(e);
      }}
      className={buttonStyle}
      disabled={disabled}
      ref={ref}
    >
      {loading ? (
        <div className="flex items-center gap-x-1">
          <LoadingSpinner size={18} />
          <span>Loading...</span>
        </div>
      ) : (
        label
      )}
    </button>
  );
};

const styleMap: Record<ButtonType, HTMLButtonElement["className"]> = {
  contained:
    "bg-primary-default text-secondary-light hover:bg-primary-dark disabled:bg-tertiary-medium disabled:text-tertiary-dark",
  soft: "bg-primary-light text-primary-default hover:bg-primary-default hover:text-secondary-light disabled:bg-tertiary-light disabled:text-tertiary-dark",
  outlined:
    "border bg-transparent text-primary-default border-primary-default hover:bg-primary-light hover:text-primary-default disabled:text-tertiary-dark disabled:border-tertiary-dark",
  text: "bg-transparent text-primary-default hover:bg-primary-light hover:text-primary-default disabled:text-tertiary-dark",
};

export const buttonRecipe = {
  redlight: {
    type: "soft",
    className: "bg-primary-redlight text-primary-error hover:bg-primary-error",
  },
} as const;

export default PrimaryLargeButton;
