"use client";
import userState from "packages/state-management/state/user.state";
import Portal from "packages/web-ui/Portal/index";
import React, { useCallback } from "react";
import CareerGuidance from "./PopUps/CareerGuidance";
import GAuthInfo from "./PopUps/GAuthInfo";
import PaymentInfo from "./PopUps/PaymentInfo";

export default function CareerGuidanceInfo() {
  const { user, setUser } = userState();

  const onDismiss = () => {
    setUser({
      pop_up: "none",
    });
  };

  switch (user.pop_up) {
    case "info":
      return (
        <Portal
          dismissable
          animateChild
          onDismiss={onDismiss}
          barrierColor="#0F274480"
        >
          <GAuthInfo onDismiss={onDismiss} />
        </Portal>
      );
    case "career":
      return (
        <Portal
          dismissable
          animateChild
          onDismiss={onDismiss}
          barrierColor="#0F274480"
        >
          <CareerGuidance onDismiss={onDismiss} />
        </Portal>
      );

    case "payment":
      return (
        <Portal
          dismissable
          animateChild
          onDismiss={onDismiss}
          barrierColor="#0F274480"
        >
          <PaymentInfo onDismiss={onDismiss} />
        </Portal>
      );

    case "none":
    default:
      return null;
  }
}
