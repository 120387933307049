import { AxiosInstance } from "axios";
import type { User } from "@encovate-mono/models/user/user.schema";
import {
  CreatePhoneUserDTO,
  UpdateUserDTO,
  UserLoginResponse,
} from "@encovate-mono/models/user/user.dto";

export default (api: AxiosInstance) => ({
  route: "/users",
  get() {
    return api.get<User>(this.route);
  },
  getByUsername(username: string) {
    return api.get<User>(this.route + "/" + username);
  },
  post(data: CreatePhoneUserDTO) {
    return api.post<UserLoginResponse>(this.route, data);
  },
  put(data: UpdateUserDTO) {
    return api.put(this.route, data);
  },
  postGoogle(idToken: string) {
    return api.post<UserLoginResponse>(this.route + "/google", {
      idToken: idToken,
    });
  },
});
