import React from "react";
import LoadingSpinner from "./LoadingSpinner";

export default function LoadingScreen() {
  return (
    <div className="h-screen w-screen grid place-items-center">
      <LoadingSpinner size={320} alignment="center" />
    </div>
  );
}
