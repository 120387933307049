import PrimaryLargeButton from "packages/web-ui/Buttons/PrimaryLargeButton";
import React from "react";

interface Props {
  onDismiss: () => void;
}
export default function PaymentInfo({ onDismiss }: Props) {
  return (
    <div className="m-4">
      <h1 className="heading-h1 mb-4">Payment Successful!</h1>
      <p className="body-b1 mb-4">
        Your Payment has been successfully processed. You will receive an
        invoice on your registered email address.
      </p>
      <PrimaryLargeButton
        label="Thank You"
        type="contained"
        onClick={onDismiss}
      />
    </div>
  );
}
