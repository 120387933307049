"use client";
import axios, { AxiosHeaders, AxiosProgressEvent } from "axios";
import { getBaseUrl } from "./common";
import userState from "@encovate-mono/state-management/state/user.state";
import { AxiosInstance } from "axios";
import { useEffect, useRef } from "react";

export function apiService(
  token = "unprotected",
  abortController: AbortController,
  headers?: Record<string, string>,
  onUploadProgress?: (e: AxiosProgressEvent) => void,
  onDownloadProgress?: (e: AxiosProgressEvent) => void
) {
  const baseUrl = getBaseUrl();
  const _headers = headers || {};
  const instance = axios.create({
    signal: abortController.signal,
    baseURL: baseUrl,
    onUploadProgress,
    onDownloadProgress,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
      "Access-Control-Allow-Origin": baseUrl,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "X-Environment": process.env.NODE_ENV,
      ..._headers,
    },
  });
  return instance;
}

export default function useApiRoute<T>(
  endPoints: (api: AxiosInstance) => T,
  onUploadProgress?: (e: AxiosProgressEvent) => void,
  onDownloadProgress?: (e: AxiosProgressEvent) => void
) {
  const { user } = userState();
  const abortController = useRef(new AbortController());
  const api = apiService(
    user.token,
    abortController.current,
    { "x-role": user.role },
    onUploadProgress,
    onDownloadProgress
  );

  useEffect(() => {
    /* if (!user.client_ready) abortController.current.abort(); */
  }, [user.client_ready]);

  return endPoints(api);
}

export function useApiInstance(
  onUploadProgress?: (e: AxiosProgressEvent) => void,
  onDownloadProgress?: (e: AxiosProgressEvent) => void
) {
  const { user } = userState();
  const abortController = useRef(new AbortController());
  const api = apiService(
    user.token,
    abortController.current,
    { "x-role": user.role },
    onUploadProgress,
    onDownloadProgress
  );

  useEffect(() => {
    /* if (!user.client_ready) abortController.current.abort(); */
  }, [user.client_ready]);

  return api;
}
