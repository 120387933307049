import {
  useSelector,
  useDispatch,
  TypedUseSelectorHook,
  Provider,
} from "react-redux";
import { configureStore, Store } from "@reduxjs/toolkit";
import { userSlice } from "./store/user.store";
import React from "react";
import { filterSlice } from "./store/filter.store";
import { menuSlice } from "./store/menu.state";

export const reducer = {
  [userSlice.name]: userSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
  [menuSlice.name]: menuSlice.reducer,
};

export const allStore = configureStore({
  reducer,
});

export type Stores = keyof typeof reducer;
export type RootState = ReturnType<typeof allStore.getState>;
export type AppDispatch = typeof allStore.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const createStore = (reducers: Array<Stores>) =>
  configureStore({
    reducer: Object.fromEntries(
      reducers.map((key) => [key, reducer[key]]),
    ) as typeof reducer,
  });

export default function StateProvider({
  children,
  listener,
  customStore = allStore,
}: {
  children: React.ReactNode;
  listener?: (data: ReturnType<(typeof allStore)["getState"]>) => void;
  customStore?: Store;
}) {
  React.useEffect(() => {
    if (!listener) return;
    const unsubscribe = allStore.subscribe(() => {
      const storeData = allStore.getState();
      listener(storeData);
    });

    return () => {
      unsubscribe();
    };
  }, [listener]);

  return <Provider store={customStore}>{children}</Provider>;
}
